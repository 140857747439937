import React, { useState, useEffect, useRef } from "react";

//components

//utils
// import { keepTheme } from "./utils/theme";

//local styles
import styles from "./App.module.css";

function App() {
  return (
    <>
      <div className={`${styles.container}  `}>
        <div className={`${styles.header} w-100 flex padding`}>
          <div className={`${styles.logo} padding`}>
            <svg
              width="162"
              height="24"
              viewBox="0 0 162 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M161.174 8.868H156.558L156.44 8.31259C156.205 7.19691 155.686 6.37683 154.937 5.82741C154.18 5.27236 153.126 4.948 151.749 4.948C149.785 4.948 148.464 5.81341 147.606 7.09457C146.726 8.40802 146.317 10.1907 146.317 11.976C146.317 13.8071 146.633 15.5955 147.441 16.9034C148.219 18.1617 149.484 19.032 151.581 19.032C154.303 19.032 156.438 17.3233 156.793 14.804H150.937V10.828H161.381V22.7H158.019L157.471 20.6922C156.908 21.3003 156.267 21.8076 155.521 22.197C154.375 22.7955 153.026 23.092 151.413 23.092C148.337 23.092 145.879 21.9188 144.204 19.8989C142.541 17.8946 141.697 15.1183 141.697 11.976C141.697 8.82627 142.658 6.05478 144.421 4.06081C146.191 2.05867 148.731 0.888 151.777 0.888C154.494 0.888 156.643 1.61669 158.208 2.9105C159.774 4.20444 160.691 6.01156 161.036 8.05128L161.174 8.868Z"
                fill="current"
              />
              <path
                d="M122.128 1.28V22.7H126.608V9.01521L134.894 22.7H139.516V1.28H135.008V14.9586L126.752 1.28H122.128Z"
                fill="current"
              />
              <path
                d="M114.514 22.7V1.28H118.994V22.7H114.514Z"
                fill="current"
              />
              <path
                d="M86.4399 2.1918L91.5455 22.7H95.9645L99.6827 8.54117L103.426 22.7H107.815L113.177 1.28H108.5L105.45 14.9077L101.843 1.28H97.5168L93.937 14.898L90.8892 1.28H81.9907L78.9408 14.9077L75.3342 1.28H71.0076L67.4277 14.898L64.38 1.28H59.7037L65.0363 22.7H69.4553L73.1735 8.54117L76.9164 22.7H81.3057L86.4399 2.1918Z"
                fill="current"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M38.6272 11.976C38.6272 15.134 39.6348 17.9162 41.4797 19.9161C43.33 21.9219 45.9786 23.092 49.1552 23.092C52.3318 23.092 54.9804 21.9219 56.8307 19.9161C58.6756 17.9162 59.6832 15.134 59.6832 11.976C59.6832 8.81785 58.6755 6.04209 56.8298 4.0489C54.979 2.05013 52.3304 0.888 49.1552 0.888C45.98 0.888 43.3314 2.05013 41.4806 4.0489C39.6349 6.04209 38.6272 8.81785 38.6272 11.976ZM49.1552 19.032C46.9932 19.032 45.5566 18.2132 44.6382 16.9795C43.6971 15.7155 43.2472 13.9418 43.2472 11.976C43.2472 10.0103 43.6971 8.24437 44.6372 6.98784C45.5547 5.76143 46.9911 4.948 49.1552 4.948C51.3193 4.948 52.7557 5.76143 53.6732 6.98784C54.6133 8.24437 55.0632 10.0103 55.0632 11.976C55.0632 13.9418 54.6133 15.7155 53.6722 16.9795C52.7538 18.2132 51.3172 19.032 49.1552 19.032Z"
                fill="current"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.0863 22.7V1.28H23.7094L31.9663 14.9586V1.28H36.4743V22.7H31.8518L23.5663 9.01521V22.7H19.0863Z"
                fill="current"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.288025 1.28V22.7H4.76802V16.0338L7.18116 13.4725L13.6726 22.7H19.0219L10.0708 10.3812L18.6223 1.28H13.2565L4.76802 10.3444V1.28H0.288025Z"
                fill="current"
              />
            </svg>
          </div>
        </div>
        <div
          className={`${styles.body}  w-100 ta-c h-70 flex flex-col flex-jc-sb`}
        >
          <div className={`${styles.text} h-70p flex flex-col `}>
            <div className={`${styles.headline} font-heading-1 padding`}>
              One Link. Many Bios.
              <br />
              Tailor your story for every platform.
            </div>
            <div className={`${styles.para} padding`}>
              An extended bio page to put in your every profile bio
            </div>
          </div>
          <div className={`${styles.join}`}>
            {/* remove data-theme from index.html after putting theme persistence component */}

            <a
              href="https://instagram.com/knoww.ing"
              target="_blank"
              rel="noreferrer"
              className={`${styles.joinWaitlist} link padding  w-20`}
            >
              <div className={`${styles.label}`}>Join Waitlist</div>
            </a>

            <div
              className={`${styles.caption} font-body-small opac-mid padding`}
            >
              with many more folks
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
